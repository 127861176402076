export enum LNBMenuType {
  /** 콘텐츠 - 동영상 */
  VIDEO = 'VIDEO',
  /** 콘텐츠 - 매거진 */
  MAGAZINE = 'MAGAZINE',
  /** 콘텐츠 - 갓찌 웹툰 */
  CARD_COMICS = 'CARD_COMICS',
  /** 콘텐츠 - 보도자료 */
  NEWS = 'NEWS',
  // 고객 요청으로 인해 주석 처리(추후 재노출 가능성 존재) - 2025.03.07
  /** 커뮤니티 - 칭찬 게시판 */
  // MENTIONS_BOARD = 'MENTIONS_BOARD',
  /** LGLIFE 공지 게시판 */
  ANNOUNCEMENT_BOARD = 'ANNOUNCEMENT_BOARD',
  /** 커뮤니티 - 자유 게시판 */
  FREE = 'FREE',
  /** 커뮤니티 - 회사 옆 맛집 게시판 */
  TOP_EATS = 'TOP_EATS',
  /** 커뮤니티 - 회사 속 직무 게시판 */
  DEPARTMENT_BOARD = 'DEPARTMENT_BOARD',
  /** 커뮤니티 - 회사 밖 소모임 게시판 */
  SOCIETY_BOARD = 'SOCIETY_BOARD',
  /** 커뮤니티(실제로는 이벤트) - LG인의 선택 */
  SIMPLE_SURVEY = 'SIMPLE_SURVEY',
  /** 이벤트 - LG인 모의고사 */
  M_QUIZ = 'M_QUIZ',
  /** 이벤트 - LG인 설문조사 */
  SURVEY = 'SURVEY',
  /** 이벤트 - 상시 이벤트 */
  EDITOR = 'EDITOR',
}

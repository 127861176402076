import { endpoints, fetcher } from 'services';
import useSWR from 'swr';
import useMyInfo from './useMyInfo';
import { useCallback } from 'react';
import { LNBMenuType } from '../types/enum/LNBMenuType';

/**
 * NEW 뱃지 확인 위한 LNB 메뉴 목록
 * - 노출 기간 하드 코딩
 */
const displayNewBadgeStatusReqParams: DisplayNewBadgeStatusListReqDto = {
  displayNewBadgeStatusReqDtoList: [
    /** 동영상 */
    {
      dayTerms: 1,
      menuType: LNBMenuType.VIDEO,
    },
    /** 갓찌 웹툰 */
    {
      dayTerms: 1,
      menuType: LNBMenuType.CARD_COMICS,
    },
    /** 매거진 */
    {
      dayTerms: 1,
      menuType: LNBMenuType.MAGAZINE,
    },
    /** LG인 모의고사 */
    {
      dayTerms: 5,
      menuType: LNBMenuType.M_QUIZ,
    },
    /** LG인 설문조사 */
    {
      dayTerms: 5,
      menuType: LNBMenuType.SURVEY,
    },
    /** LIFE 공지 */
    {
      dayTerms: 5,
      menuType: LNBMenuType.ANNOUNCEMENT_BOARD,
    }
  ],
};

const useDisplayNewBadgeStatus = () => {
  const { info } = useMyInfo();

  const { data: responseDate } = useSWR<DisplayNewBadgeStatusListResDto>(
    info ? [endpoints.common.displayNewBadgeStatus] : null,
    () => fetcher.common.displayNewBadgeStatus(displayNewBadgeStatusReqParams)
  );

  /**
   * New 뱃지 여부 조회
   * @return
   */
  const getNewBadgeMenuInfo = useCallback(
    (menuType: LNBMenuType) => {
      if (!responseDate) return;
      if (!responseDate.displayNewBadgeStatusResDtoList?.length) return;
      const displayNewBadgeStatus = responseDate.displayNewBadgeStatusResDtoList.find(item => item.menuType === menuType);
      if (!displayNewBadgeStatus) return;
      return displayNewBadgeStatus;
    },
    [responseDate]
  );

  return {
    getNewBadgeMenuInfo,
  };
};

export default useDisplayNewBadgeStatus;
